<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="出库单Id" prop="chuKuDanId" >
                <el-input v-model="form.chuKuDanId" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="出库类型" prop="chuKuLX" >
                <el-input v-model="form.chuKuLX" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="出库时间" prop="chuKuSJ">
                <el-date-picker v-model="form.chuKuSJ" size="small" type="date" value-format="yyyy-MM-dd"/>
            </el-form-item>
            <el-form-item label="商品Id" prop="shangPinId" >
                <el-input v-model="form.shangPinId" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品名称" prop="shangPinMC" >
                <el-input v-model="form.shangPinMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品货号" prop="shangPinHuoHao" >
                <el-input v-model="form.shangPinHuoHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品条码" prop="shangPinTiaoMa" >
                <el-input v-model="form.shangPinTiaoMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="成本价" prop="chengBenJia" >
                <el-input v-model="form.chengBenJia" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="出库价" prop="chuKuJia" >
                <el-input v-model="form.chuKuJia" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="库存数量" prop="kuCunSL" >
                <el-input v-model="form.kuCunSL" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="出库数量" prop="chuKuSL" >
                <el-input v-model="form.chuKuSL" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="出库金额" prop="chuKuJinE" >
                <el-input v-model="form.chuKuJinE" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="供货商" prop="gongHuoShang" >
                <el-input v-model="form.gongHuoShang" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="供货商名称" prop="gongHuoShangMC" >
                <el-input v-model="form.gongHuoShangMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品单位" prop="shangPinDW" >
                <el-input v-model="form.shangPinDW" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="生产日期" prop="shengChanRQ">
                <el-date-picker v-model="form.shengChanRQ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="保质期（天）" prop="baoZhiQiTian" >
                <el-input v-model="form.baoZhiQiTian" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="过期日期" prop="guoQiRQ">
                <el-date-picker v-model="form.guoQiRQ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="出库仓库Id" prop="chuKuCangKuId" >
                <el-input v-model="form.chuKuCangKuId" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="出库仓库" prop="chuKuCangKu" >
                <el-input v-model="form.chuKuCangKu" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="是否多规格" prop="shiFuDuoGuiGe" >
                <el-input v-model="form.shiFuDuoGuiGe" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格货号" prop="guiGeHuoHao" >
                <el-input v-model="form.guiGeHuoHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格条码" prop="guiGeTiaoMa" >
                <el-input v-model="form.guiGeTiaoMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格商品成本价" prop="guiGeShangPinChengBenJia" >
                <el-input v-model="form.guiGeShangPinChengBenJia" size="small" maxlength="18" show-word-limit/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="是否删除" prop="shiFuShanChu" >
                <el-input v-model="form.shiFuShanChu" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/kcgl/ChuKuJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                chuKuDanId: [], // 出库单Id
                chuKuLX: [], // 出库类型
                chuKuSJ: [], // 出库时间
                shangPinId: [], // 商品Id
                shangPinMC: [], // 商品名称
                shangPinHuoHao: [], // 商品货号
                shangPinTiaoMa: [], // 商品条码
                chengBenJia: [], // 成本价
                chuKuJia: [], // 出库价
                kuCunSL: [], // 库存数量
                chuKuSL: [], // 出库数量
                chuKuJinE: [], // 出库金额
                gongHuoShang: [], // 供货商
                gongHuoShangMC: [], // 供货商名称
                shangPinDW: [], // 商品单位
                shengChanRQ: [], // 生产日期
                baoZhiQiTian: [], // 保质期（天）
                guoQiRQ: [], // 过期日期
                chuKuCangKuId: [], // 出库仓库Id
                chuKuCangKu: [], // 出库仓库
                shiFuDuoGuiGe: [], // 是否多规格
                guiGeHuoHao: [], // 规格货号
                guiGeTiaoMa: [], // 规格条码
                guiGeShangPinChengBenJia: [], // 规格商品成本价
                beiZhu: [], // 备注
                shiFuShanChu: [], // 是否删除
            }
            this.titlePrefix = '出库记录';
            this.defaultForm = {
                id: null,
                chuKuDanId: "", // 出库单Id
                chuKuLX: "", // 出库类型
                chuKuSJ: "", // 出库时间
                shangPinId: "", // 商品Id
                shangPinMC: "", // 商品名称
                shangPinHuoHao: "", // 商品货号
                shangPinTiaoMa: "", // 商品条码
                chengBenJia: "", // 成本价
                chuKuJia: "", // 出库价
                kuCunSL: "", // 库存数量
                chuKuSL: "", // 出库数量
                chuKuJinE: "", // 出库金额
                gongHuoShang: "", // 供货商
                gongHuoShangMC: "", // 供货商名称
                shangPinDW: "", // 商品单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                guoQiRQ: "", // 过期日期
                chuKuCangKuId: "", // 出库仓库Id
                chuKuCangKu: "", // 出库仓库
                shiFuDuoGuiGe: "", // 是否多规格
                guiGeHuoHao: "", // 规格货号
                guiGeTiaoMa: "", // 规格条码
                guiGeShangPinChengBenJia: "", // 规格商品成本价
                beiZhu: "", // 备注
                shiFuShanChu: "", // 是否删除
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>