import { render, staticRenderFns } from "./ChuKuJiLuDetail.vue?vue&type=template&id=7b32bdcf&scoped=true&"
import script from "./ChuKuJiLuDetail.vue?vue&type=script&lang=js&"
export * from "./ChuKuJiLuDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b32bdcf",
  null
  
)

export default component.exports