<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="chuKuDanId" label="出库单Id" />
            <x-detail-item prop="chuKuLX" label="出库类型" >
                <x-dict-show :code="entity.chuKuLX" dictType="T_CHU_KU_LX"/>
            </x-detail-item>
            <x-detail-item prop="chuKuSJ" label="出库时间" />
            <x-detail-item prop="shangPinMC" label="商品名称" />
            <x-detail-item prop="shangPinHuoHao" label="商品货号" />
            <x-detail-item prop="shangPinTiaoMa" label="商品条码" />
            <x-detail-item prop="chengBenJia" label="成本价" />
            <x-detail-item prop="chuKuJia" label="出库价" />
            <x-detail-item prop="kuCunSL" label="库存数量" />
            <x-detail-item prop="chuKuSL" label="出库数量" />
            <x-detail-item prop="chuKuJinE" label="出库金额" />
            <x-detail-item prop="gongHuoShang" label="供货商" />
            <x-detail-item prop="gongHuoShangMC" label="供货商名称" />
            <x-detail-item prop="shangPinDW" label="商品单位" />
            <x-detail-item prop="baoZhiQiTian" label="保质期（天）" />
            <x-detail-item prop="chuKuCangKuId" label="出库仓库Id" />
            <x-detail-item prop="chuKuCangKu" label="出库仓库" />
            <x-detail-item prop="shiFuDuoGuiGe" label="是否多规格" >
                <x-dict-show :code="entity.shiFuDuoGuiGe" dictType="T_YES_OR_NO"/>
            </x-detail-item>
            <x-detail-item prop="guiGeHuoHao" label="规格货号" />
            <x-detail-item prop="guiGeTiaoMa" label="规格条码" />
            <x-detail-item prop="guiGeShangPinChengBenJia" label="规格商品成本价" />
            <x-detail-item prop="beiZhu" label="备注" />
<!--            <x-detail-item prop="shiFuShanChu" label="是否删除" />-->
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/kcgl/ChuKuJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                chuKuDanId: "", // 出库单Id
                chuKuLX: "", // 出库类型
                chuKuSJ: "", // 出库时间
                shangPinId: "", // 商品Id
                shangPinMC: "", // 商品名称
                shangPinHuoHao: "", // 商品货号
                shangPinTiaoMa: "", // 商品条码
                chengBenJia: "", // 成本价
                chuKuJia: "", // 出库价
                kuCunSL: "", // 库存数量
                chuKuSL: "", // 出库数量
                chuKuJinE: "", // 出库金额
                gongHuoShang: "", // 供货商
                gongHuoShangMC: "", // 供货商名称
                shangPinDW: "", // 商品单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                guoQiRQ: "", // 过期日期
                chuKuCangKuId: "", // 出库仓库Id
                chuKuCangKu: "", // 出库仓库
                shiFuDuoGuiGe: "", // 是否多规格
                guiGeHuoHao: "", // 规格货号
                guiGeTiaoMa: "", // 规格条码
                guiGeShangPinChengBenJia: "", // 规格商品成本价
                beiZhu: "", // 备注
                shiFuShanChu: "", // 是否删除
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>